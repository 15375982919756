import type { QuizContentPayload } from 'typings/graphql'

// This is a hardcoded version of the quiz questions list for the CRO-628 ab test.
// It's the same as the one in the backend, but with the frequency question added (new prefinal question).
// TODO: Implement new question list on backend and delete this hardcoded version if CRO-628 ab test wins
const questionsListAb: QuizContentPayload['questions'] = [
  {
    __typename: 'QuizQuestion',
    id: '1',
    name: 'gender',
    viewType: 'SINGLE',
    text: 'What type of fragrance are you looking for?',
    counter: '',
    answers: [
      {
        __typename: 'QuizQuestionAnswer',
        id: '1',
        answer: 'male',
        title: 'Male',
        description: null,
        body: null,
        actionName: 'Masculine',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/scent-profile-quiz-masculine-femine.jpg',
        nextQuizQuestionId: '6',
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '2',
        answer: 'female',
        title: 'Female',
        description: null,
        body: null,
        actionName: 'Feminine',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/scent-profile-quiz-masculine-femine.jpg',
        nextQuizQuestionId: '2',
      },
    ],
  },
  {
    __typename: 'QuizQuestion',
    id: '2',
    name: 'scents',
    viewType: 'SPLIT',
    text: 'What types of scents are you attracted to?',
    counter: '2 of 6',
    answers: [
      {
        __typename: 'QuizQuestionAnswer',
        id: '3',
        answer: 'warm',
        title: 'Warm',
        description: 'Comforting, sensual scents with layers of depth',
        body: 'Think opulent florals, creamy woods, and elegant sweetness.',
        actionName: 'Choose warm',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/female/scent-profile-quiz-f2-warm.jpg',
        nextQuizQuestionId: '3',
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '4',
        answer: 'fresh',
        title: 'Fresh',
        description: 'Optimistic scents, with lots of whimsy and charm',
        body: 'These bright and light fragrances create an airy, sparkly, fizzy effect.',
        actionName: 'Choose fresh',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/female/scent-profile-quiz-f2-fresh.jpg',
        nextQuizQuestionId: '3',
      },
    ],
  },
  {
    __typename: 'QuizQuestion',
    id: '3',
    name: 'mood',
    viewType: 'SPLIT',
    text: 'What mood are you looking to get into?',
    counter: '3 of 6',
    answers: [
      {
        __typename: 'QuizQuestionAnswer',
        id: '5',
        answer: 'sophisticated',
        title: 'Sophisticated',
        description: 'Complex, textured compositions with mood',
        body: 'Beautifully balanced, with layered femininity and rich, opulent notes.',
        actionName: 'Choose sophisticated',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/female/scent-profile-quiz-f3-sophisticated.jpg',
        nextQuizQuestionId: '4',
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '6',
        answer: 'playful',
        title: 'Playful',
        description: 'Fun, easy-going scents that are light and airy',
        body: 'Evoking a sense of joy and simplicity, being carefree and fancy-free.',
        actionName: 'Choose playful',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/female/scent-profile-quiz-f3-playful.jpg',
        nextQuizQuestionId: '4',
      },
    ],
  },
  {
    __typename: 'QuizQuestion',
    id: '4',
    name: 'energy',
    viewType: 'SPLIT',
    text: 'What energy are you seeking?',
    counter: '4 of 6',
    answers: [
      {
        __typename: 'QuizQuestionAnswer',
        id: '7',
        answer: 'bold',
        title: 'Bold',
        description: 'Distinctive, strong, memorable scents',
        body: 'To match your robust personality that demands attention.',
        actionName: 'Choose bold',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/female/scent-profile-quiz-f4-bold.jpg',
        nextQuizQuestionId: '200',
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '8',
        answer: 'soft',
        title: 'Soft',
        description: 'Comforting, sweet and delicious scents',
        body: 'Fragrances that embody your sense of relaxation and lightness.',
        actionName: 'Choose soft',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/female/scent-profile-quiz-f4-soft.jpg',
        nextQuizQuestionId: '200',
      },
    ],
  },
  {
    __typename: 'QuizQuestion',
    id: '200',
    name: 'frequency',
    viewType: 'SPLIT',
    text: 'How would you describe your fragrance habits?',
    counter: '5 of 6',
    answers: [
      {
        __typename: 'QuizQuestionAnswer',
        id: '201',
        answer: 'regular',
        title: 'The scent-shifter',
        description: 'Trend-forward, layering pro, switches up scents regularly',
        body: 'Switching scents whenever the mood strikes, you\'re always down to try something new.',
        actionName: 'Choose the scent-shifter',
        backgroundUrl: null,
        nextQuizQuestionId: '5',
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '202',
        answer: 'oneoff',
        title: 'The daily spritzer',
        description: 'Minimalist, effortless routine, one signature scent',
        body: 'A quick spritz in the morning to take you through the day.',
        actionName: 'Choose the daily spritzer',
        backgroundUrl: null,
        nextQuizQuestionId: '5',
      },
    ],
  },
  {
    __typename: 'QuizQuestion',
    id: '5',
    name: 'feel',
    viewType: 'CAROUSEL',
    text: 'How do you want your scent to make you feel?',
    counter: '6 of 6',
    answers: [
      {
        __typename: 'QuizQuestionAnswer',
        id: '26',
        answer: 'outgoing & adventurous',
        title: 'Outgoing & adventurous',
        description: null,
        body: null,
        actionName: 'Choose',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/female/scent-profile-quiz-f5-outgoing-adventurous.jpg',
        nextQuizQuestionId: null,
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '16',
        answer: 'sensual & chic',
        title: 'Sensual & chic',
        description: null,
        body: null,
        actionName: 'Choose',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/female/scent-profile-quiz-f5-sensual-chic.jpg',
        nextQuizQuestionId: null,
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '17',
        answer: 'playful & flirty',
        title: 'Playful & flirty',
        description: null,
        body: null,
        actionName: 'Choose',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/female/scent-profile-quiz-f5-playful-flirty.jpg',
        nextQuizQuestionId: null,
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '24',
        answer: 'sexy & mysterious',
        title: 'Sexy & mysterious',
        description: null,
        body: null,
        actionName: 'Choose',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/female/scent-profile-quiz-f5-sexy-mysterious.jpg',
        nextQuizQuestionId: null,
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '19',
        answer: 'relaxed & casual',
        title: 'Relaxed & casual',
        description: null,
        body: null,
        actionName: 'Choose',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/female/scent-profile-quiz-f5-relaxed-casual.jpg',
        nextQuizQuestionId: null,
      },
    ],
  },
  {
    __typename: 'QuizQuestion',
    id: '6',
    name: 'scents',
    viewType: 'SPLIT',
    text: 'What types of scents are you attracted to?',
    counter: '2 of 5',
    answers: [
      {
        __typename: 'QuizQuestionAnswer',
        id: '9',
        answer: 'sophisticated',
        title: 'Sophisticated',
        description: 'Multifaceted compositions that are perfectly balanced',
        body: 'Creamy gourmand notes with textured spices & woods.',
        actionName: 'Choose sophisticated',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/male/scent-profile-quiz-m2-sophisticated.jpg',
        nextQuizQuestionId: '7',
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '10',
        answer: 'sporty',
        title: 'Sporty',
        description: 'Easy-going, clean fragrances',
        body: 'These bright and light scents evoke a sense of fresh masculinity.',
        actionName: 'Choose sporty',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/male/scent-profile-quiz-m2-sporty.jpg',
        nextQuizQuestionId: '8',
      },
    ],
  },
  {
    __typename: 'QuizQuestion',
    id: '7',
    name: 'mood',
    viewType: 'SPLIT',
    text: 'What mood are you looking to get into?',
    counter: '3 of 5',
    answers: [
      {
        __typename: 'QuizQuestionAnswer',
        id: '11',
        answer: 'dark',
        title: 'Dark',
        description: 'Rich, mysterious scents with mood',
        body: 'Sensual scents with distinct personality that command attention.',
        actionName: 'Choose dark',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/male/scent-profile-quiz-m3-dark.jpg',
        nextQuizQuestionId: '100',
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '12',
        answer: 'light',
        title: 'Light',
        description: 'Subtle, fresh, comfortable scents',
        body: 'Fragrances that embody a sense of relaxation and lightness.',
        actionName: 'Choose light',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/male/scent-profile-quiz-m3-light.jpg',
        nextQuizQuestionId: '100',
      },
    ],
  },
  {
    __typename: 'QuizQuestion',
    id: '8',
    name: 'energy',
    viewType: 'SPLIT',
    text: 'What energy are you seeking?',
    counter: '3 of 5',
    answers: [
      {
        __typename: 'QuizQuestionAnswer',
        id: '13',
        answer: 'sensual',
        title: 'Sensual',
        description: 'Warm, sweet scents with depth',
        body: 'Rich spices and creamy woods wrapped in elegant sweetness.',
        actionName: 'Choose sensual',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/male/scent-profile-quiz-m4-sensual.jpg',
        nextQuizQuestionId: '100',
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '14',
        answer: 'clean',
        title: 'Clean',
        description: 'Crisp, energizing scents that radiate freshness',
        body: 'Aromatic herbs, with sparkling citrus and aquatic notes.',
        actionName: 'Choose clean',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/male/scent-profile-quiz-m4-clean.jpg',
        nextQuizQuestionId: '100',
      },
    ],
  },
  {
    __typename: 'QuizQuestion',
    id: '100',
    name: 'frequency',
    viewType: 'SPLIT',
    text: 'How would you describe your fragrance habits?',
    counter: '4 of 5',
    answers: [
      {
        __typename: 'QuizQuestionAnswer',
        id: '101',
        answer: 'regular',
        title: 'The scent-shifter',
        description: 'Trend-forward, layering pro, switches up scents regularly',
        body: 'Switching scents whenever the mood strikes, you\'re always down to try something new.',
        actionName: 'Choose the scent-shifter',
        backgroundUrl: null,
        nextQuizQuestionId: '9',
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '102',
        answer: 'oneoff',
        title: 'The daily spritzer',
        description: 'Minimalist, effortless routine, one signature scent',
        body: 'A quick spritz in the morning to take you through the day.',
        actionName: 'Choose the daily spritzer',
        backgroundUrl: null,
        nextQuizQuestionId: '9',
      },
    ],
  },
  {
    __typename: 'QuizQuestion',
    id: '9',
    name: 'feel',
    viewType: 'CAROUSEL',
    text: 'How do you want your scent to make you feel?',
    counter: '5 of 5',
    answers: [
      {
        __typename: 'QuizQuestionAnswer',
        id: '20',
        answer: 'easy going & casual',
        title: 'Easy going & casual',
        description: null,
        body: null,
        actionName: 'Choose',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/male/scent-profile-quiz-m5-easy-going-casual.jpg',
        nextQuizQuestionId: null,
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '21',
        answer: 'sophisticated & Sensual',
        title: 'Sophisticated & sensual',
        description: null,
        body: null,
        actionName: 'Choose',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/male/scent-profile-quiz-m5-sophisticated-sensual.jpg',
        nextQuizQuestionId: null,
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '15',
        answer: 'outgoing & adventurous',
        title: 'Outgoing & adventurous',
        description: null,
        body: null,
        actionName: 'Choose',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/male/scent-profile-quiz-m5-outgoing-adventurous.jpg',
        nextQuizQuestionId: null,
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '22',
        answer: 'fresh & clean',
        title: 'Fresh & clean',
        description: null,
        body: null,
        actionName: 'Choose',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/male/scent-profile-quiz-m5-fresh-clean.jpg',
        nextQuizQuestionId: null,
      },
      {
        __typename: 'QuizQuestionAnswer',
        id: '25',
        answer: 'classic & Timeless',
        title: 'Classic & timeless',
        description: null,
        body: null,
        actionName: 'Choose',
        backgroundUrl: 'https://cdn.scentbird.com/assets/discoveryQuiz/content/male/scent-profile-quiz-m5-classic-timeless.jpg',
        nextQuizQuestionId: null,
      },
    ],
  },
]

export default questionsListAb
