import { useMemo } from 'react'


type FlowValidityProps = {
  isCompleted?: boolean
  entryStep: QuizPage.LinkedQuestion
  flowSteps: QuizPage.Steps
  linkedSteps: QuizPage.LinkedQuestions
}

const isFlowValid = ({ entryStep, flowSteps, linkedSteps, isCompleted }: FlowValidityProps) => {
  // Flow is empty, this is the correct behavior for a non-final check
  if (!flowSteps.step) {
    return !isCompleted
  }

  // We did not find such a question ID, flow totally broken
  if (!entryStep) {
    return false
  }

  // If processing step with an impossible response, then fail check
  if (entryStep.id === flowSteps.step && flowSteps[flowSteps.step] !== undefined) {
    const correctAnswer = entryStep.answers.find(({ id }) => id === flowSteps[flowSteps.step])

    // If we are checking a completed quiz, the answer of entry point should not have an nextQuizQuestionId
    if (!correctAnswer || isCompleted && correctAnswer.nextQuizQuestionId) {
      return false
    }
  }

  // We have reached the root question
  if (!entryStep.prevQuizQuestionIds) {
    return true
  }

  const parentLinkedStep = linkedSteps.find(({ id, answers }) => {
    if (!entryStep.prevQuizQuestionIds.includes(id)) {
      return false
    }

    return answers.some(({ id: answerId, nextQuizQuestionId }) => {
      return flowSteps[id] === answerId && entryStep.id === nextQuizQuestionId
    })
  })

  // Parent not found
  if (!parentLinkedStep) {
    return false
  }

  return isFlowValid({ entryStep: parentLinkedStep, flowSteps, linkedSteps })
}

const useValidityOfQuizFlow = (props: FlowValidityProps): boolean => {
  const { entryStep, flowSteps, linkedSteps, isCompleted } = props

  return useMemo(() => {
    return isFlowValid({ entryStep, flowSteps, linkedSteps, isCompleted })
  }, [ entryStep, flowSteps, linkedSteps, isCompleted ])
}

export default useValidityOfQuizFlow
