import { useMemo } from 'react'
import { useQuery } from 'apollo-client'
import { useAb } from 'hooks'
import { constants } from 'helpers'

import quizContentQuery from './quizContent.graphql'
import questionsListAb from './questionsListAb'


const useQuizSteps = () => {
  const { data, isFetching, error } = useQuery(quizContentQuery)

  const isFrequencyStepAbEnabled = useAb(constants.abTests.quizFrequencyQuestion) === 'b'

  // TODO: Implement new question list on backend and delete this hardcoded version if CRO-628 ab test wins
  const questionsList = isFrequencyStepAbEnabled ? questionsListAb : data?.quizContent?.questions

  const steps = useMemo<QuizPage.LinkedQuestions>(() => {
    if (!questionsList) {
      return []
    }

    const questionMap = {}

    return questionsList.map((question) => {
      const { id: questionId, answers } = question

      questionMap[questionId] = questionMap[questionId] || {}

      const counterParts = question?.counter?.split(' of ') || []

      Object.assign(questionMap[questionId], question, {
        ...questionMap[questionId],
        stepNumber: parseInt(counterParts[0]) || undefined,
        stepsTotal: parseInt(counterParts[1]) || undefined,
      })

      answers.forEach((answer) => {
        const { nextQuizQuestionId } = answer

        if (nextQuizQuestionId) {
          if (!questionMap[nextQuizQuestionId]?.prevQuizQuestionIds) {
            questionMap[nextQuizQuestionId] = questionMap[nextQuizQuestionId] || {}
            questionMap[nextQuizQuestionId].prevQuizQuestionIds = []
          }

          questionMap[nextQuizQuestionId] = questionMap[nextQuizQuestionId] || {}
          questionMap[nextQuizQuestionId].prevQuizQuestionIds.push(questionId)
        }
      })

      return questionMap[questionId]
    })
  }, [ questionsList ])

  return {
    steps,
    error,
    isFetching,
  }
}

export default useQuizSteps
